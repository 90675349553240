<template>
    <div>
        <div v-if="hasData">
            <div class="segpreview" :height="chartOptions.height">
                <svg :id="name"></svg>
            </div>
            <div class="row">
                <div class="col">
                    <span class="badge badge-muted" v-if="data.proteins && data.proteins.length">
                        Proteins: {{ data.proteins.length }}
                    </span>
                    <span class="badge badge-muted" v-if="data.structures && data.structures.length">
                        Structures: {{ data.structures.length }}
                    </span>
                    <span class="badge badge-muted" v-if="data.models && data.models.length">
                        Models: {{ data.models.length }}
                    </span>
                    <span class="badge badge-muted" v-if="data.coverage">
                        Coverage: {{ data.coverage.total }}%
                        (<span v-if="data.coverage.structures">Structures:&nbsp;{{ data.coverage.structures }}% </span>
                        <span v-if="data.coverage.structures && data.coverage.models"> | </span>
                        <span v-if="data.coverage.models">Models:&nbsp;{{ data.coverage.models }}%</span>)
                    </span>
                </div>
            </div>
        </div>
        <div v-if="!hasData" class="text-danger">No data available for preview</div>
    </div>
</template>

<script>
export default {
    name: "SegmentPreview",

    props: {
        basePath: String, // Base path for the data
        clickRoute: String, // Route to navigate to on click
        dataServer: String, // Server to fetch data from
        name: String, //   Name of the segment
    },
    watch: {
    },
    data: function () {
        return {
            isLoading: true, // Set to true when loading data
            hasData: false, // Set to true when data is available
            previewFile: "/preview.json", // data for  preview
            chartOptions: {
                margin: { top: 20, right: 10, bottom: 5, left: 10 }, // Margins for the chart
                width: 200, // Width of the chart
                height: 60, // Height of the chart
                proteinHeight: 20, // Height of the protein track

            },
            data: {
                length: 0, // Length of the genome
                proteins: [ // Array of proteins
                    { start: 0, end: 1, name: "", label: "", reverse: false },
                ]
            }

        };
    },
    computed: {},
    mounted: function () {
        this.$nextTick(async function () {
            //on window resize, redraw the chart
            window.addEventListener("resize", this.makeChart);

            await this.loadData(); // Fetch data on component mount
            this.makeChart();
        });
    },
    methods: {
        is404(text) {
            return text.startsWith("<!DOCTYPE html>");
        },
        loadData: async function () {
            try {
                const response = await fetch(this.dataServer + this.basePath + this.previewFile);
                const data = await response.json();
                if (data) {
                    this.data = data;
                    this.hasData = true;
                    this.isLoading = false;
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        },
        makeChart() {
            // eslint-disable-next-line no-console
            // console.log(this.data)
            const self = this;
            const d3 = window.d3;
            if (!d3) {
                this.log("D3 not found");
                return;
            }
            const colors = d3.schemePastel2; // Colors for the chart
            let svgEl = d3
                .select(`#${self.name}`);
            let parent = svgEl.node().parentNode;
            let W = parent.clientWidth || self.chartOptions.width;
            svgEl.selectAll("*").remove(); // Clear the canvas
            const xMargin = self.chartOptions.margin.left + self.chartOptions.margin.right;
            const chartWidth = W - xMargin;
            svgEl.attr("width", W)
                .attr("height", self.chartOptions.height);

            let svg = svgEl
                .append("g")
                .attr("id", "varChartCanvas")
                .attr("transform", "translate( 0" + self.chartOptions.margin.left + "," + self.chartOptions.margin.top + ")");

            let scale = d3.scaleLinear().domain([0, self.data.length]).range([0, chartWidth]);
            let xAxis = d3.axisTop(scale).ticks(5).tickFormat(d3.format("d"));

            svg.append("g")
                .call(xAxis);

            // ------ arrange proteins in levels to avoid overlaps
            // modified copy of function from protael.js

            // horizontal gap between proteins, in protein coordinates fitting in X pixels
            const gap = 0;//scale.invert(1);

            // copy of array to avid modification of original array
            let pcopy = JSON.parse(JSON.stringify(self.data.proteins));
            // sort proteins by start position
            pcopy = pcopy.sort((a, b) => {
                let x = a.start - b.start
                if (x != 0)
                    return x;
                return b.end - a.end;
            });

            let levels = [];
            for (let z = 0; z < pcopy.length; z++) {
                let p = pcopy[z];
                pcopy[z].level = 0;
                let placed = false;

                // see if 
                levels.forEach((l, i) => {
                    if (!placed && p.start >= l.lastX + gap) {
                        l.elements.push(p);
                        l.lastX = p.end;
                        placed = true;
                        pcopy[z].level = i;
                    }
                });

                if (!placed) {
                    // create new level
                    levels.push({ lastX: p.end, elements: [p] });
                    pcopy[z].level = levels.length - 1;
                }
            }

            // need to rescale svg to fit all proteins
            if (levels.length > 1) {
                let h = (levels.length - 1) * self.chartOptions.proteinHeight;
                svgEl.attr("height", self.chartOptions.height + h);
            }

            const arrowTip = 8; // max width of arrow tip
            const levelsShift = 1.1 * self.chartOptions.proteinHeight; // shift between levels

            svg.append("g")
                .selectAll(".protArrow")
                .data(pcopy)
                .enter()
                .append("path")
                .attr("d", (d) => {
                    // construct path for arrow
                    // normal:
                    //  x1___________x3     y1
                    //   |............\ x2  y3
                    //   |____________/     y2

                    // reverse:
                    //      x3__________x2  y1 
                    //  x1 /............|   y3
                    //     \____________|   y2

                    const yd = d.level * levelsShift; // y shift
                    const y1 = 5 + yd; //to3
                    const y2 = self.chartOptions.proteinHeight + yd;// bottom
                    const y3 = 2.5 + self.chartOptions.proteinHeight / 2 + yd;// middle

                    const x1 = scale(d.start);
                    const x2 = scale(d.end);
                    const w = x2 - x1;
                    const tip = Math.min(w, arrowTip);
                    let x3 = d.reverse ? scale(d.start) + tip : scale(d.end) - tip;

                    if (d.reverse)
                        return `M ${x1} ${y3} L ${x3} ${y1} L ${x2} ${y1} L ${x2} ${y2} L ${x3} ${y2} Z`;

                    else
                        return `M ${x1} ${y1} L ${x3} ${y1} L ${x2} ${y3} L ${x3} ${y2} L ${x1} ${y2} L ${x1} ${y1} Z`;
                })
                .attr("fill", (d) => colors[pcopy.indexOf(d) % colors.length])
                .attr("stroke", "black")
                .attr("stroke-width", 1)
                .attr("alt", (d) => d.label)
                .append("title")
                .text((d) => { return `${d.label} [${d.start}: ${d.end}]` });

            svg.append("g")
                .selectAll("text")
                .data(pcopy)
                .enter()
                .append("text")
                .attr("x", (d) => {
                    if (d.reverse) return scale((d.start + d.end) / 2);
                    else return scale(d.start + (d.end - d.start) / 2);
                })
                .attr("y", (d) => {
                    const yd = d.level * levelsShift; // y shift
                    return self.chartOptions.proteinHeight - 4 +yd}
                )
                .attr("fill", "black")
                .attr("font-size", "10px")
                .attr("text-anchor", "middle")
                .text((d) => {
                    // rect size
                    const rectWidth = scale(d.end - d.start) - arrowTip;
                    // for font size 10px, each character is ~6px wide
                    const labelWidth = d.label.length * 6;
                    // if rectWidth is less than 20, don't show the label
                    if (rectWidth < labelWidth) return "";
                    return d.label
                });

        },
    },
};
</script>

<style scoped>
.badge-muted {
    font-weight: 100;
    color: #000000;
    background-color: #EEE;
    margin-right: 5px;
}
</style>
